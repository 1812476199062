<script>
import Layout from "@/layouts/main.vue";
import appConfig from "@/app.config";
import "@vueform/multiselect/themes/default.css";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";

import exportExcel from "@/helpers/exportExcel.js";
import Swal from "sweetalert2";
import pageLoaderOne from "@/components/page-loader-one";

export default {
  page: {
    title: "Constituency Stat",
    meta: [
      {
        name: "Constituency Stat",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      modalTitle: "",
      fileList: {},
      checkAll: false,
      title: this.$route.params.constname
        ? this.$route.params.constname + " Constituency"
        : "List",
      items: [
        {
          text: "Constituency Stat",
          href: "/",
        },
        {
          text: this.$route.params.constname
            ? this.$route.params.constname + " Constituency"
            : "List",
          active: true,
        },
      ],
      searchQuery: null,
      defaultOptions: {
        animationData: animationData,
      },

      UserDetailsData: {},
      formData: {
        name: "",
        avatar: "",
        phone: "",
        email: "",
        role_id: "",
      },
      table_options: {
        current_page: 1,
        page_size: 20,
        search_text: "",
        sort: {
          column: "",
          direction: "",
        },
      },
      meta: {},

      constituencyStats: [],
      constituencySummary: {},

      //loader
      loadingPage: true,
    };
  },

  methods: {
    //EXPORT USERS
    exportUsers() {
      this.$store.dispatch("result/exportResults").then((res) => {
        exportExcel(res, "user-list.xlsx");
      });
    },

    getConstituencyStats() {
      this.$store
        .dispatch("result/getConstituencyStats", this.$route.params.id)
        .then((res) => {
          console.log(res);
          this.constituencyStats = res;
        })
        .catch((err) => console.log(err))
          .finally(() => {
            this.loadingPage = false;

          })
      ;
    },

    getConstituencyStatsSummary() {
      this.$store
        .dispatch("result/getConstituencyStatsSummary", this.$route.params.id)
        .then((res) => {
          console.log(res);
          this.constituencySummary = res.data;
        })
        .catch((err) => console.log(err));
    },

    getTurnOut(number1, number2, number3) {
      return (
        (100 * (parseInt(number1) + parseInt(number2))) /
        parseInt(number3)
      ).toFixed(1);
    },

    openEditClient(data,isPrezOrPal) {
      if( isPrezOrPal ==="prez"){
        this.modalTitle = "Edit President Results";
      } else {
        this.modalTitle = "Edit Parliament Results";
      }

      this.formData.prezpal = isPrezOrPal
      this.formData = {
        ...data,
      };

    },

    openEditPalModal(data,isPrezOrPal) {

      this.modalTitle = "Edit Parliament Results";


      this.formData.prezpal = isPrezOrPal
      this.formData = {
        ...data,
      };

    },

    addPresidentResult() {
      let userFormData = new FormData()
      userFormData.append('prezndcvotes', this.formData.prezndcvotes)
      userFormData.append('preznppvotes', this.formData.preznppvotes)
      userFormData.append('station_id', this.formData.station_id)

      if (typeof this.formData.pinksheet !== 'undefined') {
        userFormData.append('pinksheet', this.formData.pinksheet)
      }
      console.log(this.formData.pinksheet)
      if (this.modalTitle === "Add Presidential Results") {

        this.fileList = {}
        Object.keys(this.formData).forEach(key => this.formData[key] = "")
        this.$store
            .dispatch("result/addPresidentResult", userFormData)
            .then(() => {
              this.getConstituencyStats();
              Swal.fire("Success!", "Data Added successfully.", "success");
            })
            .catch((err) => {
              console.log(err);
            });
      } else {

        userFormData.append("_method", "post")
        this.$store
            .dispatch("result/editPresidentResult", {
              id: this.formData.id,
              data: userFormData
            })
            .then(() => {
              this.getConstituencyStats();
              Swal.fire(
                  "Success!",
                  "Data updated successfully.",
                  "success"
              );
            })
            .catch((err) => {
              console.log(err);
            });
      }
      document.getElementById("closemodal").click();
      document.getElementById("addform").reset();
    },

    addParliamentResult() {
      let userFormData = new FormData()
      userFormData.append('palndcvotes', this.formData.palndcvotes)
      userFormData.append('palnppvotes', this.formData.palnppvotes)
      userFormData.append('station_id', this.formData.station_id)
      console.log(this.formData.palpinksheet)

      if (typeof this.formData.pinksheet_pal !== 'undefined') {
        userFormData.append('pinksheet', this.formData.pinksheet_pal)
      }

      if (this.modalTitle === "Add Parliamentary Results") {

        this.fileList = {}
        Object.keys(this.formData).forEach(key => this.formData[key] = "")
        this.$store
            .dispatch("result/addParliamentResult", userFormData)
            .then(() => {
              this.getConstituencyStats();
              Swal.fire("Success!", "Data Added successfully.", "success");
            })
            .catch((err) => {
              console.log(err);
            });
      } else {
        console.log(this.formData)
        userFormData.append("_method", "post")
        this.$store
            .dispatch("result/editParliamentResult", {
              id: this.formData.id,
              data: userFormData
            })
            .then(() => {
              this.getConstituencyStats();
              Swal.fire(
                  "Success!",
                  "Data updated successfully.",
                  "success"
              );
            })
            .catch((err) => {
              console.log(err);
            });
      }
      document.getElementById("closePalModal").click();
      document.getElementById("addPalForm").reset();
    },


    handleAvatar() {
      this.formData.pinksheet = this.$refs.file.files[0];
    },

    handlePalPinkSheet() {
      this.formData.pinksheet_pal = this.$refs.palpinksheet.files[0];
    },



  },
  mounted() {
    this.getConstituencyStats();
    this.getConstituencyStatsSummary();
  },

  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    pageLoaderOne
  },
};
</script>

<template>
  <Layout>
    <!-- end table responsive -->
    <PageHeader :title="title" :items="items" />


    <pageLoaderOne v-if="loadingPage"></pageLoaderOne>
    <div class="row" v-else>
      <div class="col-md-12">
        <div>
          {{ constituencySummary }}

          <br /><br />

          <div class="row h-100">
            <div class="col-lg-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm flex-shrink-0">
                      <span
                        class="avatar-title bg-light text-primary rounded-circle fs-3"
                      >
                        <i class="ri-money-dollar-circle-fill align-middle"></i>
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <p
                        class="text-uppercase fw-semibold fs-12 text-muted mb-1"
                      >
                        Prez -NDC
                      </p>
                      <h4 class="mb-0">
                        <span class="counter-value" data-target="2390.68">{{
                          constituencySummary.prezndcvotes
                        }}</span>
                      </h4>
                    </div>
                    <div class="flex-shrink-0 align-self-end">
                      <span class="badge badge-soft-success"
                        ><i class="ri-arrow-up-s-fill align-middle me-1"></i>0
                        %<span> </span
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
            <div class="col-lg-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm flex-shrink-0">
                      <span
                        class="avatar-title bg-light text-primary rounded-circle fs-3"
                      >
                        <i class="ri-arrow-up-circle-fill align-middle"></i>
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <p
                        class="text-uppercase fw-semibold fs-12 text-muted mb-1"
                      >
                        Prez - NPP
                      </p>
                      <h4 class="mb-0">
                        <span class="counter-value" data-target="19523.25">{{
                          constituencySummary.preznppvotes
                        }}</span>
                      </h4>
                    </div>
                    <div class="flex-shrink-0 align-self-end">
                      <span class="badge badge-soft-success"
                        ><i class="ri-arrow-up-s-fill align-middle me-1"></i>0
                        %<span> </span
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
            <div class="col-lg-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm flex-shrink-0">
                      <span
                        class="avatar-title bg-light text-primary rounded-circle fs-3"
                      >
                        <i class="ri-arrow-down-circle-fill align-middle"></i>
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <p
                        class="text-uppercase fw-semibold fs-12 text-muted mb-1"
                      >
                        Pal - NDC
                      </p>
                      <h4 class="mb-0">
                        <span class="counter-value" data-target="14799.44">{{
                          constituencySummary.palndcvotes
                        }}</span>
                      </h4>
                    </div>
                    <div class="flex-shrink-0 align-self-end">
                      <span class="badge badge-soft-danger"
                        ><i class="ri-arrow-down-s-fill align-middle me-1"></i>0
                        %<span> </span
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
            <div class="col-lg-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm flex-shrink-0">
                      <span
                        class="avatar-title bg-light text-primary rounded-circle fs-3"
                      >
                        <i class="ri-arrow-down-circle-fill align-middle"></i>
                      </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <p
                        class="text-uppercase fw-semibold fs-12 text-muted mb-1"
                      >
                        Pal - NPP
                      </p>
                      <h4 class="mb-0">
                        <span class="counter-value" data-target="14799.44">{{
                          constituencySummary.palnppvotes
                        }}</span>
                      </h4>
                    </div>
                    <div class="flex-shrink-0 align-self-end">
                      <span class="badge badge-soft-danger"
                        ><i class="ri-arrow-down-s-fill align-middle me-1"></i>
                        0 %<span> </span
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>

          <div
            class="row row-cols-xxl-6 row-cols-lg-3 row-cols-sm-2 row-cols-1"
          >
            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>
                      <span style="color: red">{{
                        constituencySummary.prez_results
                      }}</span>
                      of {{ constituencySummary.stations }}
                    </h4>
                    <h6 class="text-muted fs-13 mb-0">Prez Results</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>
                      <span style="color: red">{{
                        constituencySummary.prez_pinksheet
                      }}</span>
                      of {{ constituencySummary.stations }}
                    </h4>
                    <h6 class="text-muted fs-13 mb-0">Prez Pink Sheets</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>
                      <span style="color: red"
                        >{{ constituencySummary.prez_turnout }}%</span
                      >
                    </h4>
                    <h6 class="text-muted fs-13 mb-0">Prez Turnout</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>
                      <span style="color: red">{{
                        constituencySummary.pal_results
                      }}</span>
                      of {{ constituencySummary.stations }}
                    </h4>
                    <h6 class="text-muted fs-13 mb-0">Pal Results</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>
                      <span style="color: red">{{
                        constituencySummary.palpinksheet
                      }}</span>
                      of {{ constituencySummary.stations }}
                    </h4>
                    <h6 class="text-muted fs-13 mb-0">Pal Pink Sheets</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>
                      <span style="color: red">
                        {{ constituencySummary.pal_turnout }}

                        %
                      </span>
                    </h4>
                    <h6 class="text-muted fs-13 mb-0">Pal Turnout</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>
                    <button
                      type="button"
                      @click="exportUsers"
                      class="btn btn-primary m-2 add-btn"
                    >
                      Export
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <ul
                    class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active fw-semibold"
                        data-bs-toggle="tab"
                        href="#Usernav-all"
                        role="tab"
                      >
                        All

                        <span
                          class="badge badge-soft-danger align-middle rounded-pill ms-1"
                          >{{ meta.total }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">
                      Select
                      <div
                        id="select-content"
                        class="text-body fw-semibold px-1"
                      ></div>
                      Result
                      <button
                        type="button"
                        class="btn btn-link link-danger p-0 ms-3 shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#removeItemModal"
                        @click="deleteMultiple"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content text-muted">
                <div class="tab-pane active" id="Usernav-all" role="tabpanel">
                  <div
                    id="table-User-list-all "
                    class="table-card gridjs-border-none table-responsive"
                  >
                    <table
                      class="table align-middle table-nowrap mb-0 table-bordered"
                      id="customerTable"
                    >
                      <thead class="table-dark">
                        <tr>
                          <th scope="col" colspan="2" class="align-middle">
                            Station / Region
                          </th>

                          <th scope="col" colspan="2">
                            Transmitted Pinksheets
                          </th>

                          <th scope="col" colspan="1">Population</th>
                          <th scope="col" colspan="4">President</th>

                          <th scope="col" colspan="4">Parliament</th>
                          <th scope="col" colspan="1"></th>
                        </tr>
                      </thead>

                      <thead class="table-light">
                        <tr>
                          <th>Region ID</th>

                          <th>Region Name</th>

                          <th>Prez</th>
                          <th>Pal</th>
                          <th>Population</th>
                          <th>NDC</th>
                          <th>NPP</th>
                          <th>Turnout</th>
                          <th scope="col"></th>
                          <th>NDC</th>
                          <th>NPP</th>
                          <th>Turnout</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr
                          class="gridjs-tr"
                          v-for="(data, index) of constituencyStats"
                          :key="index"
                        >
                          <td class="id" hidden>
                            <a href="">{{ data.id }}</a>
                          </td>
                          <td data-column-id="name" class="gridjs-td">
                            {{ data.station.stationcode }}
                          </td>
                          <td data-column-id="name" class="gridjs-td">
                            <!--                            <router-link-->

                            <!--                                :to="{name : 'constituency-stat', params : { id : data.regionid, data: data.region } }"-->

                            <!--                                class="nav-link" data-key="t-crm">-->
                            {{ data.station.stationname }}
                            <!--                            </router-link>-->
                          </td>

                          <td data-column-id="email" class="gridjs-td">
                            {{ data.prezpinksheet }}
                          </td>

                          <td data-column-id="phone" class="gridjs-td">
                            {{ data.palpinksheet }}
                          </td>

                          <td data-column-id="role" class="gridjs-td">
                            {{ data.station.population }}
                          </td>

                          <td data-column-id="role" class="gridjs-td">
                            {{ data.prezndcvotes }}
                          </td>
                          <td data-column-id="status_id" class="gridjs-td">
                            {{ data.preznppvotes }}
                          </td>

                          <td
                            data-column-id="status_id"
                            class="gridjs-td"
                            :style="{
                              background:
                                getTurnOut(
                                  data.prezndcvotes,
                                  data.preznppvotes,
                                  data.station.population
                                ) > 90
                                  ? 'pink'
                                  : 'white',
                            }"
                          >
                            {{
                              getTurnOut(
                                data.prezndcvotes,
                                data.preznppvotes,
                                data.station.population
                              )
                            }}
                            %
                          </td>

                          <td>

                            <button @click="openEditClient(data,isPrezOrPal= 'prez')" class="dropdown-item" data-bs-toggle="modal" id="edit-btn"
                                    data-bs-target="#showPrezResultModal">
                              <i class="ri-pencil-fill align-bottom me-2 text-muted"></i>
                              Update</button>

                          </td>

                          <td data-column-id="status_id" class="gridjs-td">
                            {{ data.palndcvotes }}
                          </td>

                          <td data-column-id="status_id" class="gridjs-td">
                            {{ data.palnppvotes }}
                          </td>

                          <td
                            data-column-id="status_id"
                            class="gridjs-td"
                            :style="{
                              background:
                                getTurnOut(
                                  data.palndcvotes,
                                  data.palnppvotes,
                                  data.station.population
                                ) > 90
                                  ? 'pink'
                                  : 'white',
                            }"
                          >
                            {{
                              getTurnOut(
                                data.palndcvotes,
                                data.palnppvotes,
                                data.station.population
                              )
                            }}
                            %
                          </td>

                          <td>

                            <button @click="openEditPalModal(data,{isPrezOrPal: 'pal'})" class="dropdown-item" data-bs-toggle="modal" id="edit-btn"
                                    data-bs-target="#showPalResultModal">
                              <i class="ri-pencil-fill align-bottom me-2 text-muted"></i>
                              Update >></button>

                          </td>
                        </tr>
                      </tbody>

                      <tfoot class="table-light">
                        <tr>
                          <th></th>

                          <th></th>

                          <th>{{ constituencySummary.prez_pinksheet }}</th>
                          <th>{{ constituencySummary.palpinksheet }}</th>
                          <th>{{ constituencySummary.population }}</th>
                          <th>{{ constituencySummary.prezndcvotes }}</th>
                          <th>{{ constituencySummary.preznppvotes }}</th>

                          <th></th>
                          <th></th>
                          <th>{{ constituencySummary.palndcvotes }}</th>
                          <th>{{ constituencySummary.palnppvotes }}</th>
                          <th></th>
                          <th></th>
                          <th scope="col"></th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-published" role="tabpanel">
                  <div
                    id="table-User-list-published"
                    class="table-card gridjs-border-none"
                  ></div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-draft" role="tabpanel">
                  <div class="py-4 text-center">
                    <div>
                      <lottie
                        class="avatar-xl"
                        colors="primary:#121331,secondary:#08a88a"
                        :options="defaultOptions"
                        :height="75"
                        :width="75"
                      />
                    </div>

                    <div class="mt-4">
                      <h5>Sorry! No Result Found</h5>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->


    <!-- Add/Edit Prez Modal -->
    <div class="modal fade" id="showPrezResultModal" tabindex="-1" aria-labelledby="showPrezResultModal" aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
          </div>
          <form action="#" id="addform" @submit.prevent="addPresidentResult">
            <div class="modal-body">

              <div class="mb-3"  v-if="modalTitle === 'Add Presidential Results'">
                <label class="form-label" for="role">Station</label>
                <Multiselect required class="form-control" id="role" v-model="formData.station_id" :close-on-select="true" :searchable="true" :create-option="true" :options="userStations" />
              </div>

              <div class="mb-3" id="modal-id">
                <label for="name" class="form-label">President - NDC</label>
                <input type="text" v-model="formData.prezndcvotes" id="prezndcvotes" class="form-control" placeholder="Enter NDC Results" required />

              </div>

              <div class="mb-3">
                <label for="preznppvotes" class="form-label">President - NPP</label>
                <input type="text" v-model="formData.preznppvotes" id="preznppvotes" class="form-control" placeholder="Enter NPP Results" required />
              </div>

              <div class="mb-3">
                <label for="pinksheet" class="form-label">Pink Sheet</label>
                <input type="file" class="form-control" id="file" ref="file" @change="handleAvatar" />
              </div>

            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">
                  Close
                </button>
                <button type="submit" class="btn btn-success" id="add-btn">
                  {{ modalTitle }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Add/Edit Prez Modal -->

    <!-- Add/Edit Pal Modal -->
    <div class="modal fade" id="showPalResultModal" tabindex="-1" aria-labelledby="showPalResultModal" aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
          </div>
          <form action="#" id="addPalForm" @submit.prevent="addParliamentResult">
            <div class="modal-body">

              <div class="mb-3"  v-if="modalTitle === 'Add Parliamentary Results'">
                <label class="form-label" for="role">Station</label>
                <Multiselect required class="form-control" id="role" v-model="formData.station_id" :close-on-select="true" :searchable="true" :create-option="true" :options="userStations" />
              </div>

              <div class="mb-3" id="modal-id">
                <label for="name" class="form-label">Parliament - NDC</label>
                <input type="text" v-model="formData.palndcvotes" id="palndcvotes" class="form-control" placeholder="Enter NDC Results" required />

              </div>

              <div class="mb-3">
                <label for="palnppvotes" class="form-label">Parliament - NPP</label>
                <input type="text" v-model="formData.palnppvotes" id="palnppvotes" class="form-control" placeholder="Enter NPP Results" required />
              </div>

              <div class="mb-3">
                <label for="pinksheet" class="form-label">Pink Sheet</label>
                <input type="file" class="form-control" id="palpinksheet" ref="palpinksheet" @change="handlePalPinkSheet" />
              </div>

            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closePalModal">
                  Close
                </button>
                <button type="submit" class="btn btn-success" id="add-btn">
                  {{ modalTitle }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Add/Edit Pal Modal -->

  </Layout>
</template>
